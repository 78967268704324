/* eslint-disable */
import React, { Component, Suspense } from 'react'
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { init as initApm } from '@elastic/apm-rum'
import { useAuth } from "react-oidc-context";
import { useIdleTimer } from 'react-idle-timer'
import {
  Button, ChakraProvider, Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Text
} from "@chakra-ui/react";


import './scss/style.scss'
import { DisclaimerPopup } from "./views/lda/util/DisclaimerPopup";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const apm = initApm({
      serviceName: process.env.REACT_APP_OS_APM_SERVICE_NAME,
      serverUrl: process.env.REACT_APP_OS_APM_SERVICE_URL,
      serviceVersion: process.env.REACT_APP_OS_APM_SERVICE_VERSION,
      environment: process.env.REACT_APP_OS_APM_SERVICE_ENVIRONMENT
})

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const LoginPage = React.lazy(() => import('./pages/login/login'))
const WaitLogin = React.lazy(() => import('./pages/login/wait'))


const App = (props) => {
  const auth = useAuth();
  const [state, setState] = useState('Active')
  const [open, setOpen] = useState(false)

  const onIdle = () => {
    auth.removeUser();
    return <LoginPage error="Sie wurden aufgrund von Inaktivität ausgeloggt. Loggen Sie sich erneut ein, um die Recherche fortzusetzen."/>;
  }

  const onActive = () => {
    setState('Active')
    setOpen(false)
  }

  const onPrompt = () => {
    setState('Prompted')
    setOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: 0.5 * 3_600 * 1_000, // time out in miliseconds --> 0,5 hours
    promptBeforeIdle: 2 * 60 * 1_000, // miliseconds before timeout --> 2 min before idle --> 28min
    throttle: 1_000,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200
  })

  const handleStillHere = (visibility) => {
    setOpen(visibility)
    activate()
  }

  switch (auth.activeNavigator) {
      case "signinSilent":
          return <div>Signing you in ...</div>;
      case "signoutRedirect":
          return <div>Signing you out ...</div>;
  }

  if (auth.isLoading) {
      return <WaitLogin />;
  }

  if (auth.error) {
      //console.log("Error message: " + auth.error.message);
      return <LoginPage error={auth.error.message}/>;
  }

  if (auth.isAuthenticated) {
      //console.log("is Authenticated")
      return (
        <div>
          <BrowserRouter>
              <Suspense fallback={loading}>
                <Routes>
                  <Route path="*" name="Home" element={<DefaultLayout />} />
                </Routes>
              </Suspense>
          </BrowserRouter>

          <ChakraProvider>
          <Modal isOpen={open} onClose={() => handleStillHere(false)}>
            <ModalOverlay />
            <ModalContent sx={{ width: "80vw", maxWidth: "800px" }}>
              <ModalHeader>Sind Sie noch da?</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Heading as="h3" size="sm" mb={1} mt={4}>
                  Zweck der Applikation:
                </Heading>
                <Text fontSize="md" mb={8}>
                 Aus Sicherheitsgründen werden Sie ausgeloggt, wenn Sie länger nicht aktiv waren. Bitte bestätigen Sie, dass Sie mit OS Assist weiterhin arbeiten möchten!
                </Text>
              </ModalBody>

              <ModalFooter>
                <Button mr={3} bgColor="#035fa1"
                        _hover={{ bg: "#286c99" }}  color="white" size="lg" onClick={() => handleStillHere(false)}>
                  Ja, ich möchte eingeloggt bleiben!
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          </ChakraProvider>
        </div>
      );
  }
  else
    return <LoginPage />;
}

export default App
