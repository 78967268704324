/* eslint-disable */
import React from 'react'
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CModalBody,
} from '@coreui/react'
import {
  Button, ChakraProvider, Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Text
} from "@chakra-ui/react";

function DisclaimerPopup() {
  const [visible, setVisible] = React.useState(false);
  React.useEffect(()=>{
    let pop_status = localStorage.getItem('pop_status');
    if(!pop_status){
      setVisible(true);
      localStorage.setItem('pop_status',1);
    }
  },[])
  if(!visible) return null;

    return (

      <ChakraProvider>
      <Modal isOpen={visible} onClose={() => setVisible(false)}>
        <ModalOverlay />
        <ModalContent sx={{ width: "80vw", maxWidth: "800px" }}>
          <ModalHeader>Disclaimer - OS Assist</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading as="h3" size="sm" mb={1} mt={4}>
              Zweck der Applikation:
            </Heading>
            <Text fontSize="1rem" mb={8}>
              Der Legal Co-Pilot, sowie der Legal Data Hub - wie hier zugänglich - dient nur zur Durchführung von
              Machbarkeitsstudien sowie zur Grundlagenforschung. Der Inhalt und die Funktionen, die von dieser Applikation
              bereitgestellt werden, dienen ausschließlich Informations- und Bildungszwecken und dürfen nicht als Grundlage
              für rechtliche Entscheidungen herangezogen werden.
            </Text>
            <Heading as="h3" size="sm" mb={1}>
              Kein Ersatz für professionelle Unterstützung:
            </Heading>
            <Text fontSize="1rem" mb={8}>
              Die bereitgestellten Informationen dürfen unter keinen Umständen als Ersatz für professionelle Beratung von
              Rechtsanwälten, Steuerberatern, etc. angesehen werden.
            </Text>
            <Heading as="h3" size="sm" mb={1}>
              Verarbeiten Sie keine persönlichen Informationen:
            </Heading>
            <Text fontSize="1rem" mb={8}>
              Bitte beachten Sie, dass Sie keine persönlich identifizierbaren Informationen hochladen oder verarbeiten.
              Anonymisieren Sie Ihre Eingaben vorab.
            </Text>
            <Heading as="h3" size="sm" mb={1}>
              Limitiertes Wissen:
            </Heading>
            <Text fontSize="1rem" mb={8}>
              Bitte beachten Sie, dass die LDA Legal Data Analytics GmbH bemüht ist, den Legal Data Hub stets auf dem
              neuesten Stand zu halten und Informationen regelmäßig zu aktualisieren. Die absolute Vollständigkeit und
              Aktualität aller Arten von Informationen (insb. der Dokumente) kann aber nicht gewährleistet werden.
            </Text>
            <Heading as="h3" size="sm" mb={1}>
              Weitergabe von Zugängen und Wissen:
            </Heading>
            <Text fontSize="1rem">
              Bitte beachten Sie, dass die Weitergabe von Zugängen zur Applikation und zum Legal Data Hub und Legal Co-Pilot
              (inkl. Screenshots) nur nach vorheriger Rücksprache und schriftlicher Erlaubnis der LDA Legal Data Analytics
              GmbH gestattet ist.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} bgColor="#035fa1"
                    _hover={{ bg: "#286c99" }}  color="white" size="lg" onClick={() => setVisible(false)}>
              Ich akzeptiere
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </ChakraProvider>
    )
}

export { DisclaimerPopup }
